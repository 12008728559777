<template>
  <v-card
      :tile="textCard"
      elevation="1"
      min-height="280"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
  >
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <v-toolbar class="py-0 px-0 buttons" color="grey darken-3" elevation="1">
          <v-toolbar-title class="font-weight-medium white--text">Розподілений об'єм буд. лічильника</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="shrink">
            <v-row class="align-center">
              <v-btn @click="navigation = false"
                     class="grey darken-3"
                     icon
                     outlined
              >
                <v-icon color="secondary">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-tabs class="document-small-tabs" color="success">
          <v-tab>Населення</v-tab>
          <v-tab>Юр. особи</v-tab>

          <v-tab-item class="pt-3">
            <TableComponent
                :header="value_is_heating ? resultHeaderHeating : resultHeaderNonHeating"
                :items="resultItems"
                :small="false"
                :scrollable="true"
                :show_footer="true"
            />
          </v-tab-item>
          <v-tab-item class="pt-3">
            <TableComponent
                :header="value_is_heating ? resultHeaderHeating : resultHeaderNonHeating"
                :items="resultItemsLegacy"
                :small="false"
                :scrollable="true"
                :show_footer="true"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-bottom-sheet>
    <v-toolbar elevation="0" class="pa-0 mb-3">
      <v-toolbar-title class="toolbar-work-place-title">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :icon="!button" :depressed="button" class="grey lighten-4 mr-2" @click="deleteDialog"
             v-if="selectedCounter.id">
        <v-icon color="error">mdi-delete</v-icon>
      </v-btn>
      <v-btn :icon="!button" :depressed="button" class="grey lighten-4 mr-2"
             @click="recalculation"
             :loading="recalculation_loading"
             v-if="selectedCounter.id">
        <v-icon color="success">mdi-calculator</v-icon>
      </v-btn>
      <MainModal
          v-if="selectedCounter.id"
          :main="{ component: 'BuildingCounterReading', title: 'Покази лічильника' }"
          :button="{
                    icon: !button,
                    color: 'grey lighten-4',
                    buttonIcon: 'mdi-plus',
                    buttonName: button ? 'Подати показник' : '',
                    buttonOutlined: button,
                    buttonIconClass: button ? 'mr-2' : ''
                  }"
          :item="{
                  building_counter_id: selectedCounter ? selectedCounter.id : 0,
                  service_id: selectedCounter ? selectedCounter.service_id : 0,
                  serial_number: selectedCounter ? selectedCounter.serial_number : '',
                  month: current_month,
                  submission_date: current_date,
                  zone: selectedCounter ? selectedCounter.zone : 0,
                  prev_value_first: getPrevValue(1),
                  prev_value_second: getPrevValue(2),
                  prev_value_third: getPrevValue(3)
          }"
      />
    </v-toolbar>

    <TableComponent
        :header="meterReadingHeaders"
        :items="meter_reading_data"
        :small="true"
        v-if="meter_reading_data.length"
        @values_detalization="openResult"
    >
    </TableComponent>
  </v-card>
</template>
<script>
import {getAccountIcon, getAddBuildingCounterValueMethod} from "@/utils/icons"
import MainModal from "@/components/modal/MainModal";
import TableComponent from "@/components/tableComponent/TableComponent"
import {mapGetters} from "vuex";
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";
import {ALERT_SHOW} from "@/store/actions/alert";
import {RECALCULATION_BUILDING_COUNTER, REMOVE_BUILDING_COUNTER_READING} from "@/store/actions/counter";
import buildingCounterAPI from "@/utils/axios/building_counter"

const modalDeleteId = "delete_building_meter_reading"

export default {
  name: "HWP_BuildingPokaz",
  components: {
    MainModal,
    TableComponent
  },
  props: {
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Архів показів'
    },
    person_hash: {
      type: String,
      default: ''
    },
    button: {
      type: Boolean,
      default: false
    },
    personal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navigation: false,
      recalculation_loading: false,
      resultHeaderHeating: [
        {
          text: '',
          value: 'icon',
          width: 60,
          type: 'icon',
          iconFunc: getAccountIcon
        },
        {text: 'Адреса', value: 'address', width: 300},
        {text: 'Показ. розрах.', value: 'by_full_volume', width: 130},
        {text: 'Результат', value: 'result', width: 140},
        {text: 'По ліч.', value: 'has_counter', type: 'icon', width: 90, bool: true},
        {text: 'МЗК', value: 'mzk'},
        {text: 'ФСО', value: 'fso'},
        {text: 'Дорах.до.мін', value: 'additional_result'}
      ],
      resultHeaderNonHeating: [
        {
          text: '',
          value: 'icon',
          width: 60,
          type: 'icon',
          iconFunc: getAccountIcon
        },
        {text: 'Адреса', value: 'address', width: 300},
        {text: 'Показ. розрах.', value: 'by_full_volume', width: 130},
        {text: 'Результат', value: 'result', width: 140},
        {text: 'По ліч.', value: 'has_counter', type: 'icon', width: 90, bool: true},
      ],
      value_is_heating: false,
      resultItems: [],
      resultItemsLegacy: [],
      meterReadingHeaders: [
        {
          text: '',
          value: 'icon',
          width: 60,
          type: 'icon',
          iconFunc: getAccountIcon
        },
        {
          text: 'Місяць',
          value: 'month',
          type: 'date',
          format: 'MMMM YY р.',
          width: 110,
          style: {'font-weight: 500 !important': v => v.month === `${this.current_month}`}
        },
        {
          text: 'Дата подання', value: 'submission_date', type: 'date',
          width: 110,
          style: {'font-weight: 500 !important': v => v.month === `${this.current_month}`}
        },
        {
          text: 'Зона',
          value: 'zone_name',
          chip: {small: true, color: 'grey lighten-4'},
        },
        {text: 'Попередній', value: 'prev_value_first'},
        {
          text: 'Поточний',
          value: 'current_value_first',
        },
        {
          text: 'Різниця',
          value: 'value_difference_first',
          style: {'font-weight: 500': v => v.month === `${this.current_month}`}
        },
        {
          text: 'Спосіб подання',
          value: 'value_add_method',
          type: 'get',
          func: getAddBuildingCounterValueMethod,
          width: 160,
        },
        {
          text: 'Розподіл',
          value: 'result',
          emit: 'values_detalization',
          if_filled: true,
          chip: {small: true, color: 'success', chip_text: 'Відкрити'},
        }
      ],
      delete_watcher: null,
      delete_meter_id: 0
    }
  },
  methods: {
    getPrevValue(zone) {
      const zones = {
        1: ['start_meter_reading_first', 'current_meter_reading_first'],
        2: ['start_meter_reading_second', 'current_meter_reading_second'],
        3: ['start_meter_reading_third', 'current_meter_reading_third'],
      }
      if (!this.selectedCounter) return 0
      if (!zone) return 0

      const zone_obj = zones[zone]

      if (this.selectedCounter[zone_obj[1]]) return this.selectedCounter[zone_obj[1]]
      return this.selectedCounter[zone_obj[0]]
    },
    openResult(payload) {
      buildingCounterAPI.get_counter_reading_values(payload.id)
        .then(response => response.data)
        .then(data => {
          this.resultItems = data.filter(i => !i.is_legacy)
          this.resultItemsLegacy = data.filter(i => i.is_legacy)

          if (this.resultItems.length) {
            this.value_is_heating = this.resultItems[0].is_heating || false
          }

          if (this.resultItemsLegacy.length) {
            this.value_is_heating = this.resultItemsLegacy[0].is_heating || false
          }

          this.navigation = true
        })
    },
    deleteDialog() {
      const filtered = this.meter_reading_data.filter(item => item.month === this.current_month)
      if (!filtered.length) {
        this.$store.dispatch(ALERT_SHOW, {text: 'Не знайдено показів за поточний місяць', color: 'error lighten-1'})
        return
      }

      const last_item = filtered.slice(-1)[0]

      this.delete_meter_id = Object.assign({}, last_item)

      const payload = {
        text: `Підтвердіть вилучення показника: ${last_item.current_value_first} від ${formatDate(last_item.submission_date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    recalculation() {
      const filtered = this.meter_reading_data.filter(item => item.month === this.current_month)
      if (!filtered.length) {
        this.$store.dispatch(ALERT_SHOW, {text: 'Не знайдено показів за поточний місяць', color: 'error lighten-1'})
        return
      }

      const last_item = filtered.slice(-1)[0]

      this.delete_meter_id = Object.assign({}, last_item)

      const payload = {
        building_counter_value_id: this.delete_meter_id.id,
        building_counter_id: this.delete_meter_id.building_counter_id,
        month: this.delete_meter_id.month
      }
      this.recalculation_loading = true

      this.$store.dispatch(RECALCULATION_BUILDING_COUNTER, payload)
          .then(ok => {
            if (ok) {
              this.$store.dispatch(ALERT_SHOW, {text: 'Перерахунок по будинковому лічильнику завершений!', color: 'success'})
            }
          })
          .finally(() => {
            this.recalculation_loading = false
          })
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const payload_local = {
                    id: this.delete_meter_id.id,
                    building_counter_id: this.delete_meter_id.building_counter_id,
                    month: this.delete_meter_id.month
                  }
                  this.$store.dispatch(REMOVE_BUILDING_COUNTER_READING, payload_local)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
                this.delete_meter_id = {}
              }
            }
          }
      )
    },
  },
  computed: {
    ...mapGetters({
      selectedCounter: 'getSelectedBuildingCounter',
      current_month: 'getCurrentMonth',
      meter_reading_data: 'getBuildingMeterReading',
      modalAnswer: 'question_answer'
    }),
    current_date() {
      const now = new Date()
      const start_month_now = new Date(now.getFullYear(), now.getMonth(), 1);
      const month = new Date(this.current_month)
      const end_of_month = new Date(month.getFullYear(), month.getMonth() + 1, 0)

      if (start_month_now > month) {
        return formatDate(end_of_month.toISOString(), 'YYYY-MM-DD')
      } else {
        return formatDate(now.toISOString(), 'YYYY-MM-DD')
      }
    },
  },
  created() {
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  },
}
</script>

<style scoped>

</style>