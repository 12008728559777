<template>
  <v-card
      :tile="textCard"
      elevation="1"
      min-height="280"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      class="pay-table-wrapper"
  >
    <MainModal
        :main="{ component: 'BuildingCounter', title: 'Будинкові прилади обліку' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_building_counter)"
        :modal="show_building_counter_dialog"
        @updateItemModal="buildingCounterUpdateItemModal"
    />
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <v-toolbar class="py-0 px-0 buttons" color="grey darken-3" elevation="1">
          <v-toolbar-title class="font-weight-medium white--text">Приєднані абоненти</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="shrink">
            <v-row class="align-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="success mr-3"
                      icon
                      outlined
                      height="40"
                      width="40"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="white" size="22">
                      mdi-gesture-tap-button
                    </v-icon>
                  </v-btn>
                </template>
                <v-list nav dense>
                  <v-list-item @click="fillDocument">
                    <v-list-item-icon class="mr-3">
                      <v-icon color="success">
                        mdi-file-table-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Заповнити</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="clearDocument">
                    <v-list-item-icon class="mr-3">
                      <v-icon color="error lighten-1">
                        mdi-delete-empty-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Очистити</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                  class="secondary mr-3"
                  icon
                  outlined
                  height="40"
                  width="40"
                  @click="saveDocument">
                <v-icon color="white" size="22">
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-btn @click="navigation = false"
                     class="error mr-2"
                     icon
                     outlined
                     height="40"
                     width="40"
              >
                <v-icon color="white" size="22">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <BuildingCounterUser
            :modal="navigation"
            :update_data="update_data"
            :event_obj="event_obj"
            :building_counter_id="selectedCounter.id"
            :building_id="selectedCounter.building_id"
        />
      </v-card>
    </v-bottom-sheet>
    <v-toolbar elevation="0" class="pa-0">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed class="grey lighten-4 mr-2" v-if="selectedCounter.id" @click="navigation = true">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Приєднані абоненти
      </v-btn>
      <v-tooltip bottom color="success"  v-if="!readOnly">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="openBuildingCounterCreateDialog"
                 v-bind="attrs"
                 v-on="on"
                 class="grey lighten-4 mr-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Створити новий будинковий лічильник</span>
      </v-tooltip>
    </v-toolbar>
    <div class="pay-table" style="height: 100%">
      <div class="pay-table-header">
        <div class="pay-table-row">
          <div class="pay-table-col"></div>
          <div class="pay-table-col"></div>
          <div class="pay-table-col">
            Назва
          </div>
          <div class="pay-table-col">
            Серійний номер
          </div>
          <div class="pay-table-col">
            Модель
          </div>
          <div class="pay-table-col">
            Встановлено
          </div>
          <div class="pay-table-col">
            Зона
          </div>
          <div class="pay-table-col">
            Початковий
          </div>
          <div class="pay-table-col">
            Поточний
          </div>
          <div class="pay-table-col"></div>
        </div>
      </div>
      <div class="pay-table-body" style="height: calc(100% - 105px); overflow: auto;">
        <div
            class="pay-table-row"
            :class="{'expandable': item.zone > 0,
                           'expanded': item.row_num === expand_row_num}"
            v-for="(item, index) in filteredItems" :key="`item-${item.row_num}-${index}`">
          <template v-if="!item.zone">
            <div class="pay-table-col" style="padding-left: 10px !important;">
              <v-checkbox
                  class="select-checkbox"
                  color="success"
                  hide-details :ripple="false"
                  :value="selected_row === item.id"
                  @change="changeSelect(item.id)"
              />
            </div>
            <div class="pay-table-col">
              <template v-if="readOnly">
                <v-icon :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-2">
                  {{ getAccountIcon(item.icon, 'icon') }}
                </v-icon>
              </template>
              <template v-else>
                <v-icon
                    size="24" :color="getAccountIcon(item.icon, 'color')"
                    class="mr-2"
                    @click.stop="onBuildingCounterItemClick(item)"
                >
                  {{ getAccountIcon(item.icon, 'icon') }}
                </v-icon>
              </template>
            </div>
            <div class="pay-table-col">
              <span>
                {{ item.name }}
              </span>
            </div>
            <div class="pay-table-col">
              <span>
                {{ item.serial_number }}
              </span>
            </div>
            <div class="pay-table-col">
              <span>
                {{ item.model_name }}
              </span>
            </div>
            <div class="pay-table-col">
              <span>
                {{ item.date_start | formatDate }}
              </span>
            </div>
            <div class="pay-table-col" style="display: flex">
              <v-icon style="width: 28px" size="24" class="mr-3">
                mdi-checkbox-blank-circle-outline
              </v-icon>
              <div style="line-height: 25px; max-width: 220px">
                <v-chip small class="cs-chip">
                  Без зони
                </v-chip>
              </div>
            </div>
            <div class="pay-table-col">
              <span>
                {{ item.start_meter_reading_first }}
              </span>
            </div>
            <div class="pay-table-col">
              <span>
                {{ item.current_meter_reading_first }}
              </span>
            </div>
            <div class="pay-table-col"></div>
          </template>
          <template v-else>
            <div class="pay-table-row-expand-header" :class="{'expanded': item.row_num === expand_row_num}">
              <div class="pay-table-col" style="padding-left: 10px !important;">
                <v-checkbox class="select-checkbox"
                            color="success"
                            hide-details
                            :ripple="false"
                            :value="selected_row === item.row_num"
                            @change="changeSelect(item.row_num)"/>
              </div>
              <div class="pay-table-col">
                <template v-if="readOnly">
                  <v-icon :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-2">
                    {{ getAccountIcon(item.icon, 'icon') }}
                  </v-icon>
                </template>
                <template v-else>
                  <v-icon
                      size="24" :color="getAccountIcon(item.icon, 'color')"
                      class="mr-2"
                      @click.stop="onBuildingCounterItemClick(item)"
                  >
                    {{ getAccountIcon(item.icon, 'icon') }}
                  </v-icon>
                </template>
              </div>
              <div class="pay-table-col">
                <span>
                  {{ item.name }}
                </span>
              </div>
              <div class="pay-table-col">
                <span>
                  {{ item.name }}
                </span>
              </div>
              <div class="pay-table-col">
                <span>
                  {{ item.model_name }}
                </span>
              </div>
              <div class="pay-table-col">
                <span>
                  {{ item.date_start | formatDate }}
                </span>
              </div>
              <div class="pay-table-col" style="display: flex">
                <v-btn icon small class="mr-3" @click="expandRow(item.row_num)">
                  <v-icon :color="item.row_num === expand_row_num ? 'primary' : 'grey darken-1'">
                    {{
                      item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                    }}
                  </v-icon>
                </v-btn>
                <div style="line-height: 25px; max-width: 220px">
                  <v-chip small class="cs-chip">
                    {{ item.zone === 2 ? 'Ніч' : 'Напівпік'}}
                  </v-chip>
                </div>
              </div>
              <div class="pay-table-col">
                <span>
                  {{ item.start_meter_reading_first }}
                </span>
              </div>
              <div class="pay-table-col">
                <span>
                  {{ item.current_meter_reading_first }}
                </span>
              </div>
              <div class="pay-table-col">
                <span></span>
              </div>
            </div>
            <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
              <template v-if="item.zone === 2">
                <div class="pay-table-expand-body-row">
                  <div class="pay-table-col"></div>
                  <div class="pay-table-col" style="display: flex">
                    <v-icon style="width: 28px" color="primary" size="20"
                            class="mr-3">
                      mdi-speedometer
                    </v-icon>
                    <div style="line-height: 25px; max-width: 220px">
                      <v-chip small class="cs-chip">
                        Ніч
                      </v-chip>
                    </div>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.start_meter_reading_second }}
                    </span>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.current_meter_reading_second }}
                    </span>
                  </div>
                  <div class="pay-table-col"></div>
                </div>
              </template>
              <template v-if="item.zone === 3">
                <div class="pay-table-expand-body-row">
                  <div class="pay-table-col"></div>
                  <div class="pay-table-col" style="display: flex">
                    <v-icon style="width: 28px" color="primary" size="20"
                            class="mr-3">
                      mdi-speedometer
                    </v-icon>
                    <div style="line-height: 25px; max-width: 220px">
                      <v-chip small class="cs-chip">
                        Ніч
                      </v-chip>
                    </div>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.start_meter_reading_second }}
                    </span>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.current_meter_reading_second }}
                    </span>
                  </div>
                  <div class="pay-table-col">
                    <span></span>
                  </div>
                </div>
                <div class="pay-table-expand-body-row">
                  <div class="pay-table-col"></div>
                  <div class="pay-table-col" style="display: flex">
                    <v-icon style="width: 28px" color="primary darken-1" size="20"
                            class="mr-3">
                      mdi-speedometer
                    </v-icon>
                    <div style="line-height: 25px; max-width: 220px">
                      <v-chip small class="cs-chip">
                        Пік
                      </v-chip>
                    </div>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.start_meter_reading_third }}
                    </span>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.current_meter_reading_third }}
                    </span>
                  </div>
                  <div class="pay-table-col">
                    <span></span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {formatDate} from "@/filters";
import {mapActions, mapGetters} from "vuex";
import {
  BUILDING_COUNTER_SELECT,
  GET_BUILDING_COUNTER,
  GET_BUILDING_COUNTER_READING
} from "@/store/actions/counter";

export default {
  name: "BuildingCounter",
  props: {
    service_id: {
      type: Number,
      default: undefined
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Прилади обліку'
    },
    modal: {
      type: Boolean,
      default: false
    },
    date_start: {
      type: String,
      default: '2020-01-01'
    },
    person_hash: {
      type: String,
      default: ''
    },
    updatePayArchive: {
      type: Boolean,
      default: false
    },
    personal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'getBuildingCounter_',
      selectedCounter: 'getSelectedBuildingCounter',
    }),
    filteredItems() {
      if (this.service_id === undefined || this.service_id === null) {
        return this.items
      } else {
        return this.items.filter(item => item.service_id === this.service_id)
      }
    }
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    BuildingCounterUser: () => import("@/components/BuildingCounterUser"),
  },
  data() {
    return {
      show_fill_dialog: false,
      dialog_id: 0,
      selected_row: 0,
      expand_row_num: 0,
      service_id_watcher: null,
      navigation: false,
      update_data: false,
      openDial: false,
      selectedRows: [],
      event_obj: {
        delete_button: false,
        add_button: false,
        clear_all_button: false,
        fill_button: false,
        save_button: false
      },
      selected_building_counter: {},
      show_building_counter_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_BUILDING_COUNTER,
      fetchCounterReading: GET_BUILDING_COUNTER_READING
    }),
    saveDocument() {
      this.event_obj.save_button = true
    },
    fillDocument() {
      this.event_obj.fill_button = true
    },
    clearDocument() {
      this.event_obj.clear_all_button = true
    },
    getAccountIcon,
    formatDate,
    expandRow(row_num) {
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
    },
    changeSelect(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = 0

        this.$store.dispatch(BUILDING_COUNTER_SELECT, {})
      } else {
        this.selected_row = row_num
        const row_obj = this.items.find(item => item.id === this.selected_row)
        this.$store.dispatch(BUILDING_COUNTER_SELECT, row_obj)
            .then(() => {
              this.$store.dispatch(GET_BUILDING_COUNTER_READING, row_obj.id)
            })
      }
    },
    buildingCounterUpdateItemModal() {
      this.show_building_counter_dialog = false
      this.selected_building_counter = {}
    },
    openBuildingCounterCreateDialog() {
      this.selected_building_counter = {}
      this.show_building_counter_dialog = true
    },
    onBuildingCounterItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.counter_name = payload.name

      this.selected_building_counter = local_payload
      this.show_building_counter_dialog = true
    },
    // addNewRow() {
    //   this.event_obj.add_button = true
    // },
    // deleteRows() {
    //   this.event_obj.delete_button = true
    // },
  },
  created() {
    this.fetch()
  },
  beforeDestroy() {
    if (this.service_id_watcher) {
      this.service_id_watcher()
    }
    this.$store.dispatch(BUILDING_COUNTER_SELECT, {})
  },
}
</script>

<style scoped lang="scss">

.pay-table-col {
  padding: 2px 2px !important;

  &:nth-child(1) {
    flex: 0 1 60px;
    padding-left: 16px !important;
    min-width: 60px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
  }
  &:nth-child(3) {
    flex: 0 1 220px;
    min-width: 200px;
  }

  &:nth-child(4) {
    flex: 0 1 160px;
    min-width: 140px;
  }

  &:nth-child(5) {
    flex: 0 1 140px;
    min-width: 140px;
  }

  &:nth-child(6) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(7) {
    flex: 0 1 140px;
    min-width: 140px;
  }

  &:nth-child(8) {
    flex: 0 1 120px;
    min-width: 120px;
    text-align: right;
  }

  &:nth-child(9) {
    flex: 0 1 120px;
    min-width: 120px;
    text-align: right;
  }

  &:nth-child(10) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 740px;
        min-width: 700px;
      }

      &:nth-child(2) {
        flex: 0 1 140px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 140px;
      }

      &:nth-child(3) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(4) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(5) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
      }
    }
  }
}

</style>