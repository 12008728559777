<template>
  <v-container fluid class="pt-0" style="height: 100%; display: flex;">
    <v-row style="display: flex; flex-direction: column; width: 100%">
      <v-col cols="12" md="12" style="flex: 0">
        <v-sheet
            class="mx-auto mt-4 transparent"
            max-width="100%"
        >
          <v-slide-group
              v-model="slider"
              center-active
              show-arrows
              class="slider"
              @change="changeSlider"
              v-if="services.length"
          >
            <v-slide-item
                v-for="(item, index) in services"
                :key="index"
                v-slot="{ active, toggle }"
            >
              <v-card
                  :color="active ? 'grey darken-1' : 'white'"
                  class="mx-4 mb-1"
                  width="300"
                  @click="toggle"
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon :color="active ? 'white' : getAccountIcon(item.type, 'color')" size="32">
                      {{ active ? 'mdi-check-all' : getAccountIcon(item.type, 'icon') }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-subtitle
                        class="text-pre-wrap"
                        :class="active ? 'white--text' : 'grey--text text--darken-3'"
                    >
                      {{ item.text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <v-card
              color="white"
              class="mx-4 mb-1"
              v-else
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="error lighten-1" size="32">
                  mdi-comment-processing-outline
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle
                    class="text-pre-wrap grey--text text--darken-3"
                >
                  Послуги які використовують лічильникик - відустні. Перейдіть у розділ Послуги та відмітьте послуги у яких є лічильники
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="12" class="pb-1" style="flex: 0">
        <BuildingCounter :service_id="service_id" height="320px" :title="'Будинкові прилади обліку'"></BuildingCounter>
      </v-col>
      <v-col cols="12" md="12" class="pt-1" style="flex: 1">
        <HWP_BuildingPokaz :counter_id="selected.length ? selected[0].id : -1" height="100%"></HWP_BuildingPokaz>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BuildingCounter from "@/components/BuildingCounter";
import HWP_BuildingPokaz from "@/components/HouseWorkPlace/HWP_BuildingPokaz";
import {mapActions, mapGetters} from "vuex";
import {SELECT_SERVICES_WITH_COUNTER} from "@/store/actions/services";
import {getAccountIcon} from "@/utils/icons";
import {BUILDING_COUNTER_SELECT} from "@/store/actions/counter";

export default {
  name: "BuildingCounterView",
  components: {
    BuildingCounter,
    HWP_BuildingPokaz,
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithCounter',
    })
  },
  data() {
    return {
      selected: [],
      slider: null,
      service_id: null,
      navigation: false
    }
  },
  methods: {
    ...mapActions({
      fetch_services: SELECT_SERVICES_WITH_COUNTER
    }),
    getAccountIcon,
    changeSlider(e) {
      if (e !== undefined) {
        this.service_id = this.services[e].value
        this.$store.dispatch(BUILDING_COUNTER_SELECT, {})
      } else {
        this.service_id = null
        this.$store.dispatch(BUILDING_COUNTER_SELECT, {})
      }
    }
  },
  created() {
    this.fetch_services()
  }
}
</script>

<style scoped lang="scss">
  .slider {
    &:deep(.v-slide-group__prev) {
      i {
        font-size: 50px;
      }
    }
    &:deep(.v-slide-group__next) {
      margin-left: 14px;
      i {
        font-size: 50px;
      }
    }
  }
</style>